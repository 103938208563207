<template>
  <div class="">
    <redirect class="mb-5" title="Post Purchase" route="website.upsell.post_purchase" />
    <h1 class="page-title mb-6">
      {{ $t(isEditPage ? 'Update Post Purchase' : 'Create Post Purchase') }}
    </h1>
    <template v-for="(item, index) in ['create', 'update']">
      <PostPurchase
        v-if="isEditPage && item === 'update' && !isLoading"
        :type="item"
        :defaultData="formData"
        :key="index"
        :directToBootUpSell="true"
      />
      <PostPurchase
        v-if="!isEditPage && item === 'create'"
        :type="item"
        :defaultData="formData"
        :key="index"
        :directToBootUpSell="true"
      />
    </template>
  </div>
</template>

<script>
import PostPurchase from '@/views/website/apps/UpSell/main-upsell/components/PostPurchase.vue';
import { offerApi } from '@/apis/offers';
import { convertIdsToArrays } from '@/helpers/convertData';
import { required } from 'vuelidate/lib/validators';

export default {
  components: {
    PostPurchase,
  },
  data() {
    return {
      isEditPage: this.$route.name === 'website.upsell.post_purchase.update',
      isLoading: false,
      dataDefault: {
        collectionRecommendIds: [],
        collectionTargetIds: [],
        productRecommendIds: [],
        productTargetIds: [],
      },
      defaultFormData: {},
      formData: {
        sameProductType: null,
        activated: true,
        offerMessage: 'Complete your order with {{product name}}',
        offerType: 'prePurchase',
        priority: 0,
        offerName: '',
        offerTitle: null,
        recommendIds: [], // product collection ids ,
        recommendType: null, //product, collection, sameCollection, relevantCollection
        targetIds: [],
        targetType: null, //all, product, collection
        store: '',
        enableDiscount: true,
        percentageDiscount: 0,
        discountData: [],
        countries: null,
        countdownTime: 5,
        isFreeShipping: true,
        shippingRate: 0,
        viewInOrder: true,
      },
    };
  },
  validations: {
    formData: {
      offerName: {
        required,
      },
    },
  },
  methods: {
    async getPostPurchase() {
      if (this.$route.params.id) {
        try {
          this.isLoading = true;
          const res = (await offerApi.getById(this.$route.params.id)).data.offer;
          const [
            collectionRecommendIds,
            collectionTargetIds,
            productRecommendIds,
            productTargetIds,
          ] = await Promise.all([
            convertIdsToArrays('collection', res.collectionRecommendIds),
            convertIdsToArrays('collection', res.collectionTargetIds),
            convertIdsToArrays('product', res.productRecommendIds),
            convertIdsToArrays('product', res.productTargetIds),
          ]);
          this.dataDefault = {
            collectionRecommendIds,
            collectionTargetIds,
            productRecommendIds,
            productTargetIds,
          };
          this.formData = {
            activated: res.activated,
            sameProductType: res.sameProductType,
            offerMessage: res.offerMessage,
            offerType: res.offerType,
            priority: res.priority,
            offerName: res.offerName,
            offerTitle: res.offerTitle,
            recommendIds: [], // product collection ids ,
            recommendType: res.recommendType, //product, collection, sameCollection, relevantCollection
            targetIds: [],
            targetType: res.targetType, //all, product, collection
            enableDiscount: res.enableDiscount,
            percentageDiscount: res.percentageDiscount,
            discountData: res.discountData,
            countries: res.countries,
            countdownTime: res.countdownTime,
            isFreeShipping: res.isFreeShipping,
            shippingRate: res.shippingRate,
            viewInOrder: res.viewInOrder,
          };
          this.defaultFormData = Object.assign({}, res);
          if (this.formData.recommendType === 'product' || this.formData.recommendType === 'collection') {
            this.formData.recommendIds = this.dataDefault[`${this.formData.recommendType}RecommendIds`];
            this.dataDefault[`${this.formData.recommendType}RecommendIds`] = this.dataDefault[
              `${this.formData.recommendType}RecommendIds`
            ];
          }
          if (this.formData.targetType === 'product' || this.formData.targetType === 'collection') {
            this.formData.targetIds = this.dataDefault[`${this.formData.targetType}TargetIds`];
            this.dataDefault[`${this.formData.targetType}TargetIds`] = this.dataDefault[
              `${this.formData.targetType}TargetIds`
            ];
          }
        } catch (error) {
          console.log('🚀 ~ file: index.vue:122 ~ getPostPurchase ~ error:', error);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
  created() {
    if (this.isEditPage) {
      this.getPostPurchase();
    }
  },
};
</script>

<style lang="scss" scoped></style>
